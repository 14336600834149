<template>
  <section>
    <content-header :title="$t('officeMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterOffices')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="search(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfOffices')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => $router.push({name: 'OfficeCreate'})"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(office, index) in offices.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="office"
            @on-edit="onEdit(office)"
            @on-clone="onClone(office)"
            @block="lockOrUnlock(office.locationId)"
            @on-delete="openDeleteModal(office)"
            @on-sync-chat-users="showSyncChatUsersConfirmation(office)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="offices.count > 0"
        id="pgPager"
        class="my-4"
        :data="offices"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>

    <BigLoader v-if="loading" />
    <DeleteModal
      v-model="showDeleteModal"
      error-message="officeNameIsNotCorrect"
      :item="editModel"
      label="officeName"
      name="locationName"
      @on-confirm="onDelete()"
    />

    <confirmation-modal
      v-model="showSyncChatModal"
      text="syncChatUsersConfimation"
      @on-confirm="syncChatUsers()"
      @on-reject="showSyncChatModal = false"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';

import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';

import {
  searchLocations as _searchLocations,
  deleteLocation as _deleteLocation,
  lockUnlockLocation as _lockUnlockLocation,
} from '@/services/LocationService';

import {
  syncChatUserAccounts as _syncChatUserAccounts
} from '@/services/UserService'

import DeleteModal from '@/components/DeleteModal';

import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';

export default {
  name: 'RegisteredServiceQueues',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
    DeleteModal,
    ConfirmationModal
  },
  data() {
    return {
      filters: {
        organizations: [],
        companies: [],
      },
      selectedFilters: {
        organization: [],
        company: [],
      },
      offices: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      loading: false,
      editModel: null,
      selectedOffice: null,
      showSyncChatModal: false
    };
  },
  mounted() {
    this.getOrganizations();
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'organization',
          label: this.$t('organization'),
          component: 'FilterMultiSelect',
          options: this.filters.organizations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.organization,
          onChanged: this.onOrganizationChanged,
        },
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
      ];
    },
  },
  methods: {
    async getOrganizations() {
      await _getOrganizations()
        .then((response) => {
          this.filters.organizations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCompanies(organization) {
      await _getCompanies(organization)
        .then((response) => {
          this.filters.companies = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onOrganizationChanged(value) {
      this.selectedFilters.organization = [];
      this.selectedFilters.company = [];

      if (value) {
        this.selectedFilters.organization = [value];
        this.getCompanies(this.selectedFilters.organization[0].value);
      }
    },
    onCompanyChanged(value) {
      this.selectedFilters.company = [];

      if (value) {
        this.selectedFilters.company = [value];
      }
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        organizationId: this.selectedFilters.organization.length
          ? this.selectedFilters.organization[0].value
          : null,
        companyId: this.selectedFilters.company.length
          ? this.selectedFilters.company[0].value
          : null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _searchLocations(payload)
        .then(({ data }) => {
          this.offices = data;
          this.noResultsFound = !this.offices.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = data;
    },
    lockOrUnlock(userId) {
      _lockUnlockLocation(userId)
        .then(async () => {
          this.ShowSuccessSaveToast();
          await this.search();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onDelete() {
      _deleteLocation(this.editModel.locationId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    showSyncChatUsersConfirmation(data) {
      this.selectedOffice = data;
      this.showSyncChatModal = true;
    },
    async syncChatUsers(){
      await _syncChatUserAccounts({ locationConfigurationId: this.selectedOffice.locationConfigurationId })
        .then(() => {
          this.showSyncChatModal = false;
          this.ShowSuccessSaveToast();
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
