<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('appointmentModule')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.enabledAppointment" switch>
                    {{ $t('activeAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.filterByExtraField1" switch>
                    {{ $t('filterTypeOfServicesByNumberOfLoans') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.coordinateFilter" switch>
                    {{ $t('coordinatorFilter') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.allowCoordinateOtherService"
                    switch
                  >
                    {{ $t('allowCoordinateOtherService') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.noCoordinateInternalAppointments"
                    switch
                  >
                    {{ $t('doNotAllowCoordinatingInternalAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.noCoordinateInternalAppointmentsToday"
                    switch
                  >
                    {{ $t('doNotAllowCoordinatingInternalAppointmentsToday') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.hideAttachDocument" switch>
                    {{ $t('hideAttachDocument') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <filter-numeric
                    v-model="value.shiftCompanions"
                    :clearable="true"
                    :min="0"
                    :title="$t('companionsAllowedPerShift')"
                  />
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.shiftCompanionsLabel"
                    :label="$t('etiquetteEscorts')"
                  />
                </div>
                <div class="col-md-6 pt-4">
                  <filter-numeric
                    v-model="value.appointmentStartDays"
                    :clearable="true"
                    :min="0"
                    :title="$t('minimumDaysToCoordinateAppointments')"
                  />
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-checkbox
                        v-model="value.preventCoordinateAppointment"
                        switch
                      >
                        {{ $t('preventCoordinateAppointment') }}
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="value.preventCoordinateAppointment"
                      class="col-md-12"
                    >
                      <filter-numeric
                        v-model="value.maxDayCoordinateAppointment"
                        :clearable="true"
                        :min="0"
                        :title="$t('maxDayCoordinateAppointment')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 5)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import {
  getOfficeAppointment as _getOfficeAppointment,
  updateOfficeAppointment as _updateOfficeAppointment,
} from '@/services/LocationService';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'appointmentModule',
  components: {
    SubHeader,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await _getOfficeAppointment(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.enabledAppointment = data.enabled;
        this.value.shiftCompanions = data.shiftCompanions;
        this.value.filterByExtraField1 = data.filterByExtraField1;
        this.value.shiftCompanionsLabel = data.shiftCompanionsLabel;
        this.value.coordinateFilter = data.coordinateFilter;
        this.value.allowCoordinateOtherService =
          data.allowCoordinateOtherService;
        this.value.noCoordinateInternalAppointments =
          data.noCoordinateInternalAppointments;
        this.value.noCoordinateInternalAppointmentsToday =
          data.noCoordinateInternalAppointmentsToday;
        this.value.preventCoordinateAppointment =
          data.preventCoordinateAppointment;
        this.value.maxDayCoordinateAppointment =
          data.maxDayCoordinateAppointment;
        this.value.hideAttachDocument = data.hideAttachDocument;
        this.value.appointmentStartDays = data.appointmentStartDays;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      await _updateOfficeAppointment({
        ...this.value,
        enabled: this.value.enabledAppointment,
      }).then(() => {
        if (next) this.$emit('continue', 7);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
