<template>
  <validation-observer ref="form" class="row" novalidate tag="section">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required">
          <filter-select
            v-model="alertId"
            fieldtext="name"
            fieldvalue="id"
            :error="errors[0]"
            :error-msg="$t('mustSelectOne')"
            :label="$t('alert')"
            :options="alerts"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        {{$t('roles')}}:
        <b-form-checkbox
          v-for="(role, i) in availableRoles"
          v-model="role.selected"
          switch
          :key="i"
        >
          {{ role.name }}
        </b-form-checkbox>
      </div>
      <div class="col-md-12 text-right mt-3">
        <button class="btn btn-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
      </div>
  </validation-observer>
</template>

<script>
import { updateOfficeAlerts as _updateOfficeAlerts } from '@/services/LocationService';

export default {
  name: 'AlertForm',
  props: {
    locationConfigurationId: {
      type: Number,
      required: true,
    },
    alerts: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      alertId: null,
      availableRoles: this.roles.map((x) => ({ ...x, selected: false })),
    };
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      await _updateOfficeAlerts({
        locationConfigurationId: this.locationConfigurationId,
        configurations: this.availableRoles
          .filter((x) => x.selected)
          .map((x) => ({ roleId: x.id, alertId: this.alertId })),
      }).then(() => {
        this.$emit('close');
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
