<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('kioskParameters')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <base-input
                    v-model="value.kioskButtonCode"
                    :label="$t('kioskButtonCode')"
                  />
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.appointmentsEnabled" switch>
                    {{ $t('allowAppointmentAtKiosk') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.chineseSupport" switch>
                    {{ $t('chineseVersionKiosk') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.allowMultipleTurnsPerService" switch>
                    {{ $t('allowMultipleTurnsPerService') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.hasToleranceForCitedTurn"
                    switch
                  >
                    {{ $t('toleranceQuotedTimeToGetTurns') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.haveToleranceBeforeAppointmentHour"
                    switch
                  >
                    {{ $t('haveToleranceBeforeAppointmentHour') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <filter-numeric
                    v-model="value.toleranceTimeInMinutes"
                    :title="$t('toleranceQuotedTimeToGetTurn')"
                    :min="0"
                    :max="200"
                  />
                </div>
                <div class="col-md-6">
                  <filter-numeric
                    v-model="value.toleranceBeforeAppointmentHour"
                    :title="$t('toleranceBeforeAppointmentHour')"
                    :clearable="true"
                    :min="0"
                    :max="200"
                  />
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 3)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import {
  getOfficeKiosk as _getOfficeKiosk,
  updateOfficeKiosk as _updateOfficeKiosk,
} from '@/services/LocationService';

export default {
  name: 'kioskParameters',
  components: {
    SubHeader,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await _getOfficeKiosk(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.kioskButtonCode = data.kioskButtonCode;
        this.value.toleranceTimeInMinutes = data.toleranceTimeInMinutes;
        this.value.appointmentsEnabled = data.appointmentsEnabled;
        this.value.hasToleranceForCitedTurn = data.hasToleranceForCitedTurn;
        this.value.chineseSupport = data.chineseSupport;
        this.value.haveToleranceBeforeAppointmentHour = data.haveToleranceBeforeAppointmentHour;
        this.value.toleranceBeforeAppointmentHour = data.toleranceBeforeAppointmentHour;
        this.value.allowMultipleTurnsPerService = data.allowMultipleTurnsPerService;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      await _updateOfficeKiosk(this.value).then(() => {
        if (next) this.$emit('continue', 5);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
