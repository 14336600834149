<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />

    <div id="tab-container">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <Information
          v-if="isActive(0)"
          :value="model"
          @continue="onStepChange($event)"
          @disabled-geographic="disabledGeographic = $event"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <Parameter
          v-if="isActive(1)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(2) }"
        role="tabpanel"
      >
        <Manager
          v-if="isActive(2)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(3) }"
        role="tabpanel"
      >
        <Printer
          v-if="isActive(3)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(4) }"
        role="tabpanel"
      >
        <Kiosk
          v-if="isActive(4)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>

      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(5) }"
        role="tabpanel"
      >
        <Survey
          v-if="isActive(5)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(6) }"
        role="tabpanel"
      >
        <Appointment
          v-if="isActive(6)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(7) }"
        role="tabpanel"
      >
        <Integrations
          v-if="isActive(7)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(8) }"
        role="tabpanel"
      >
        <Alerts
          v-if="isActive(8)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(9) }"
        role="tabpanel"
      >
        <SpecialField
          v-if="isActive(9)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(10) }"
        role="tabpanel"
      >
        <Address
          v-if="isActive(10)"
          :value="model"
          @continue="onStepChange($event)"
          @disabled-geographic="disabledGeographic = $event"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(11) }"
        role="tabpanel"
      >
        <Geographic
          v-if="isActive(11)"
          :value="model"
          @continue="onStepChange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Information from './components/Information';
import Parameter from './components/Parameter';
import Manager from './components/Manager';
import Printer from './components/Printer';
import Kiosk from './components/Kiosk';
import Survey from './components/Survey';
import Appointment from './components/Appointment';
import Integrations from './components/Integrations';
import Alerts from './components/Alerts';
import SpecialField from './components/SpecialField';
import Address from './components/Address';
import Geographic from './components/Geographic';

export default {
  name: 'OfficeMaster',
  components: {
    StepWizard,
    Information,
    Parameter,
    Manager,
    Printer,
    Kiosk,
    Survey,
    Appointment,
    Integrations,
    Alerts,
    SpecialField,
    Address,
    Geographic,
  },
  data() {
    return {
      stepIndex: 0,
      model: {
        // Start information properties
        locationConfigurationId: null,
        organizationId: null,
        name: null,
        companyId: null,
        categoryId: null,
        municipalityId: null,
        description: null,
        typeId: null,
        goLiveDate: null,
        phone: null,
        hoursText: null,
        // Start parameter properties
        turnWaitingTime: null,
        hasAutomaticService: false,
        hasMobileAccess: false,
        isClientPhoneRequired: false,
        canCreateAccount: false,
        canAutoClearList: false,
        canActivatePWA: false,
        canAskForStation: false,
        canActivateOffice: false,
        alertWithTurnCount: null,
        automaticQueueType: null,
        recordsPerPage: null,
        openTime: null,
        closeTime: null,
        autoCloseUserAccount: false,
        priorityCallingTurnByQueue: false,
        priorityCallingTurnByService: false,
        preferentialTurn: false,
        mobileCanFollowTurn: false,
        canUploadDocuments: false,
        enableLanguageBehavior: false,
        enableChat: false,
        enableEntrance: false,
        preferredLanguageId: null,
        enableVisitMode: false,
        enableTurnManualCompletion: false,

        // Start manager properties
        noShowDisplayTimeInSeconds: null,
        hasAutomaticPull: false,
        isAutomaticEquitative: false,
        canReasignQueue: false,
        canEditClientInfo: false,
        hasReadonlyService: false,
        serviceEndTime: null,
        hasAutomaticPush: false,
        isBreakModuleEnabled: false,
        hasIndividualServiceCounter: false,
        canCallIndividually: false,
        canRecoordinateAppointments: false,
        // Start printer properties
        isPrinterEnabled: false,
        copiesToPrint: null,
        isSpecific: false,
        printerName: null,
        // Start kiosk properties
        kioskButtonCode: null,
        toleranceTimeInMinutes: null,
        appointmentsEnabled: false,
        hasToleranceForCitedTurn: false,
        chineseSupport: false,
        haveToleranceBeforeAppointmentHour: false,
        toleranceBeforeAppointmentHour: null,
        // Start display properties
        showName: false,
        showNumber: false,
        voiceEnabled: false,
        volumeInVideos: null,
        // Start survey properties
        enabledSurvey: false,
        remoteSurveyId: null,
        remoteLocationId: null,
        // Start appointment properties
        enabledAppointment: false,
        shiftCompanions: null,
        filterByExtraField1: false,
        shiftCompanionsLabel: null,
        coordinateFilter: false,
        allowCoordinateOtherService: false,
        noCoordinateInternalAppointments: false,
        noCoordinateInternalAppointmentsToday: false,
        preventCoordinateAppointment: false,
        maxDayCoordinateAppointment: false,
        appointmentStartDays: null,
        // Start integrations properties
        enableColecturiaDigital: false,
        askIdWhenCancelingStamp: false,
        enableSingleSignOn: false,
        enableMSTeams: false,
        // Start extra fields properties
        specialField1Enabled: false,
        specialField1Name: null,
        specialField1Required: false,
        specialField1ShowInHandler: false,
        specialField1ShowInAptsMobile: false,
        specialField1ShowInAptsWeb: false,
        specialField1ShowInTurnosMobile: false,
        specialField1Mask: null,

        specialField2Enabled: false,
        specialField2Name: null,
        specialField2Required: false,
        specialField2ShowInHandler: false,
        specialField2ShowInAptsMobile: false,
        specialField2ShowInAptsWeb: false,
        specialField2ShowInTurnosMobile: false,
        specialField2Mask: null,

        specialField3Enabled: false,
        specialField3Name: null,
        specialField3Required: false,
        specialField3ShowInHandler: false,
        specialField3ShowInAptsMobile: false,
        specialField3ShowInAptsWeb: false,
        specialField3ShowInTurnosMobile: false,

        specialField4Enabled: false,
        specialField4Name: null,
        specialField4Required: false,
        specialField4ShowInHandler: false,
        specialField4ShowInAptsMobile: false,
        specialField4ShowInAptsWeb: false,
        specialField4ShowInTurnosMobile: false,
        specialField4Mask: null,

        specialField5Enabled: false,
        specialField5Name: null,
        specialField5Required: false,
        specialField5ShowInHandler: false,
        specialField5ShowInAptsMobile: false,
        specialField5ShowInAptsWeb: false,
        specialField5ShowInTurnosMobile: false,
        specialField5Mask: null,

        specialField6Enabled: false,
        specialField6Name: null,
        specialField6Required: false,
        specialField6ShowInHandler: false,
        specialField6ShowInAptsMobile: false,
        specialField6ShowInAptsWeb: false,
        specialField6ShowInTurnosMobile: false,
        specialField6Mask: null,

        specialField7Enabled: false,
        specialField7Name: null,
        specialField7Required: false,
        specialField7ShowInHandler: false,
        specialField7ShowInAptsMobile: false,
        specialField7ShowInAptsWeb: false,
        specialField7ShowInTurnosMobile: false,

        // Start address properties
        physicalAddressId: null,
        physicalAddress1: null,
        physicalAddress2: null,
        physicalCityId: null,
        physicalState: null,
        physicalCountry: null,
        physicalZipCode: null,
        physicalZipCodeExt: null,
        postalAddressId: null,
        postalAddress1: null,
        postalAddress2: null,
        postalCityId: null,
        postalState: null,
        postalCountry: null,
        postalZipCode: null,
        postalZipCodeExt: null,
        // Star geo properties
        latitude: null,
        longitude: null,
        logo: null,
        logoFile: null,
      },
      disabledGeographic: true,
    };
  },
  computed: {
    tabs() {
      const firstTab = [
        {
          name: this.$t('officeInformation'),
          icon: 'fas fa-plus',
          disabled: false,
        },
      ];
      if (!this.model.locationConfigurationId) return firstTab;

      const tabs = [
        {
          name: this.$t('officeParameter'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('handlerParameters'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('printerParameters'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('kioskParameters'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('surveyModule'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('appointmentModule'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('integrations'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('alerts'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('extraField1100'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('address1200'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('geographicCoordinates'),
          icon: 'fas fa-plus',
          disabled: this.disabledGeographic,
        },
      ];
      return firstTab.concat(tabs);
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
