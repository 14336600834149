<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('officeInformation')" />
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.organizationId"
                  fieldtext="text"
                  fieldvalue="value"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  :label="$t('organization')"
                  :options="collections.organizations"
                  :disabled="!!value.locationConfigurationId"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required|max:128">
                <base-input
                  v-model="value.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('name')"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.companyId"
                      fieldtext="text"
                      fieldvalue="value"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('company')"
                      :options="collections.companies"
                      :disabled="!!value.locationConfigurationId"
                    />
                  </validation-provider>
                </div>
                <div v-if="!value.locationConfigurationId" class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.municipalityId"
                      fieldtext="name"
                      fieldvalue="id"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('municipality')"
                      :options="collections.municipalities"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <base-datetime-picker
                    format="dd/MMM/yyyy"
                    :title="$t('goLive')"
                    type="date"
                    :value="value.goLiveDate"
                    @change="value.goLiveDate = $event"
                  />
                </div>
                <div
                  v-if="value.locationConfigurationId"
                  class="col-md-12 pt-1 mb-3"
                >
                  <button
                    class="btn btn-outline-info mt-4 mb-1"
                    @click="showNotificationModal = true"
                  >
                    {{ $t('notifications') }}
                  </button>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:128">
                    <base-input
                      v-model="value.description"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('descriptionServicesOffered')"
                      :textarea="true"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <custom-image-input
                    v-model="value.logo"
                    :auto-delete="false"
                    :file="value.logoFile"
                    :label="$t('logo')"
                    :max-height="512"
                    :max-width="512"
                    @clearFile="onDeleteImage()"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <filter-select
                    v-model="value.categoryId"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('officeCategory')"
                    :options="collections.categories"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.typeId"
                      fieldtext="name"
                      fieldvalue="id"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('officeType')"
                      :options="collections.types"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.phone"
                    :label="$t('phone')"
                    mask-type="Phone"
                    :add-mask="true"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:128">
                    <base-input
                      v-model="value.hoursText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('schedules')"
                      :textarea="true"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12 mt-4">
                  <b-form-checkbox v-model="value.hasPresential" switch>
                    {{ $t('hasPresential') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />

      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button
        v-if="value.locationConfigurationId"
        class="btn btn-primary ml-2"
        @click="onSubmit(true)"
      >
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
    <custom-modal
      v-model="showNotificationModal"
      :title="$t('officeNotifications')"
      size="lg"
    >
      <Notifications
        v-if="showNotificationModal"
        :location-configuration-id="value.locationConfigurationId"
      />
    </custom-modal>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import Notifications from './Notifications.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import {
  getOfficeCategories as _getOfficeCategories,
  getOfficeTypes as _getOfficeTypes,
} from '@/services/LocationService';
import {
  createUpdateOffice as _createUpdateOffice,
  deleteImage as _deleteImage,
} from '@/services/LocationService';
import { getMunicipalities as _getMunicipalities } from '@/services/MunicipalityService';
import { getOfficeInformation as _getOfficeInformation } from '@/services/LocationService';
import CustomImageInput from '@/components/CustomImageInput';

export default {
  name: 'OfficeInformation',
  components: {
    SubHeader,
    BaseInput,
    Notifications,
    CustomModal,
    CustomImageInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collections: {
        companies: [],
        categories: [],
        types: [],
        municipalities: [],
        organizations: [],
      },
      showNotificationModal: false,
    };
  },
  async mounted() {
    await _getOrganizations()
      .then((response) => {
        this.collections.organizations = response.data || [];
        if (this.value.organizationId)
          this.loadCompanies(this.value.organizationId);
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    await _getOfficeCategories()
      .then((response) => {
        this.collections.categories = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    await _getOfficeTypes()
      .then((response) => {
        this.collections.types = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));

    if (this.value.locationConfigurationId) return;

    await _getMunicipalities({ stateCode: 'PR' })
      .then((response) => {
        this.collections.municipalities = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  watch: {
    'value.organizationId'(value) {
      this.loadCompanies(value);
    },
    $route: {
      immediate: true,
      handler(val) {
        this.$emit('disabled-geographic', true);
        if (val.name === 'OfficeCreate') return;
        this.value.locationConfigurationId = Number(val.params.id);
        _getOfficeInformation(this.value.locationConfigurationId).then(
          ({ data }) => {
            this.value.organizationId = data.organizationId;
            this.value.name = data.name;
            this.value.companyId = data.companyId;
            this.value.categoryId = data.categoryId;
            this.value.municipalityId = data.municipalityId;
            this.value.description = data.description;
            this.value.typeId = data.typeId;
            this.value.goLiveDate = data.goLiveDate;
            this.value.phone = data.phone;
            this.value.hoursText = data.hoursText;
            this.value.logoFile = data.logoFile;
            this.value.hasPresential = data.hasPresential;

            this.$emit('disabled-geographic', !data.havePhysicalAddress);
          },
        );
      },
    },
  },
  methods: {
    async loadCompanies(value) {
      if (!this.collections.organizations.length) return;
      this.collections.companies = [];
      if (!value) return (this.value.companyId = null);
      await _getCompanies([value])
        .then((response) => {
          this.collections.companies = response.data;
          const company = this.collections.organizations.find(
            (x) => x.value == value,
          );
          if (!company) this.value.companyId = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;

      _createUpdateOffice(this.value)
        .then((response) => {
          if (!this.value.locationConfigurationId) {
            this.$router.push({
              name: 'OfficeEdit',
              params: { id: response.data },
            });
          }

          if (next) this.$emit('continue', 1);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onDeleteImage() {
      _deleteImage(this.value.locationConfigurationId)
        .then(() => {
          this.value.logo = null;
          this.value.logoFile = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
