<template>
  <div class="mt-5 container-fluid">
    <div class="row">
      <content-header :title="$t('cloneOffice')" />
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="form" novalidate>
              <div class="row">
                <div class="col-md-4">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.organizationId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="text"
                      fieldvalue="value"
                      :label="$t('organization')"
                      :options="collections.organizations"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.companyId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="text"
                      fieldvalue="value"
                      :label="$t('company')"
                      :options="collections.companies"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:250"
                  >
                    <base-input
                      v-model="model.description"
                      :label="$t('name')"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <filter-select
                    v-model="rows"
                    :is-multiple="true"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('row')"
                    :options="collections.rows"
                  />
                </div>
                <div class="col-md-6">
                  <filter-select
                    v-model="services"
                    :is-multiple="true"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('service')"
                    :options="collections.services"
                  />
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <hr />
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <h3 class="text-center" style="color: #ff8105">
                            {{ $t('addressPostal') }}
                          </h3>
                        </div>

                        <div class="col-md-12 mt-4">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:200"
                          >
                            <base-input
                              v-model="model.postalAddress1"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('urbCondResAptdo')"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:200"
                          >
                            <base-input
                              v-model="model.postalAddress2"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('numberStreetApt')"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <filter-select
                              v-model="model.postalCityId"
                              fieldtext="name"
                              fieldvalue="id"
                              :error="errors[0]"
                              :error-msg="$t('mustSelectOne')"
                              :label="$t('city')"
                              :options="collections.municipalities"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:2"
                          >
                            <base-input
                              v-model="model.postalState"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('state')"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:50"
                          >
                            <base-input
                              v-model="model.postalCountry"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('country')"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:10"
                          >
                            <base-input
                              v-model="model.postalZipCode"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('postalCode')"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:4"
                          >
                            <base-input
                              v-model="model.postalZipCodeExt"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('postalCodeExt')"
                            />
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <h3 class="text-center" style="color: #ff8105">
                            {{ $t('physicalAddress') }}
                          </h3>
                        </div>
                        <div class="col-md-12">
                          <b-form-checkbox v-model="sameAddress" switch>
                            {{ $t('physicalAddressEqualToPostal') }}
                          </b-form-checkbox>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:200"
                          >
                            <base-input
                              v-model="model.physicalAddress1"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('urbCondResAptdo')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:200"
                          >
                            <base-input
                              v-model="model.physicalAddress2"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('numberStreetApt')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <filter-select
                              v-model="model.physicalCityId"
                              fieldtext="name"
                              fieldvalue="id"
                              :error="errors[0]"
                              :error-msg="$t('mustSelectOne')"
                              :label="$t('city')"
                              :options="collections.municipalities"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:2"
                          >
                            <base-input
                              v-model="model.physicalState"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('state')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:50"
                          >
                            <base-input
                              v-model="model.physicalCountry"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('country')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|max:10"
                          >
                            <base-input
                              v-model="model.physicalZipCode"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('postalCode')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>

                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="max:4"
                          >
                            <base-input
                              v-model="model.physicalZipCodeExt"
                              :error="errors[0]"
                              :error-msg="$t('invalidField')"
                              :label="$t('postalCodeExt')"
                              :disabled="sameAddress"
                            />
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $router.push({ name: 'Offices' })"
          :text="$t('cancel')"
        />

        <button class="btn btn-primary" @click="onSubmit()">
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import ContentHeader from '@/components/Header';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { copyOffice as _copyOffice } from '@/services/LocationService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import {
  getQueueList as _getQueueList,
  getCodeServiceTypesListByLocation as _getCodeServiceTypesListByLocation,
} from '@/services/ServicesService';
import { getMunicipalities as _getMunicipalities } from '@/services/MunicipalityService';

export default {
  name: 'ServiceQueueClone',
  components: {
    BaseInput,
    ContentHeader,
  },
  data() {
    return {
      model: {
        organizationId: null,
        companyId: null,
        description: null,
        physicalAddressId: null,
        physicalAddress1: null,
        physicalAddress2: null,
        physicalCityId: null,
        physicalState: null,
        physicalCountry: null,
        physicalZipCode: null,
        physicalZipCodeExt: null,
        postalAddressId: null,
        postalAddress1: null,
        postalAddress2: null,
        postalCityId: null,
        postalState: null,
        postalCountry: null,
        postalZipCode: null,
        postalZipCodeExt: null,
      },
      sameAddress: false,
      collections: {
        organizations: [],
        companies: [],
        rows: [],
        services: [],
        municipalities: [],
      },
      rows: [],
      services: [],
      locationConfigurationId: null,
    };
  },
  async mounted() {
    this.locationConfigurationId = Number(this.$route.params.id);
    this.getOrganizations();

    await _getQueueList(this.locationConfigurationId)
      .then((response) => {
        this.collections.rows = response.data;
        this.rows = this.collections.rows.map((x) => x.id);
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));

    await _getCodeServiceTypesListByLocation(this.locationConfigurationId)
      .then((response) => {
        this.collections.services = response.data;
        this.services = this.collections.services.map((x) => x.id);
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));

    await _getMunicipalities({ stateCode: 'PR' })
      .then((response) => {
        this.collections.municipalities = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },

  watch: {
    model: {
      deep: true,
      handler() {
        this.copyAddress();
      },
    },
    'model.organizationId'() {
      this.model.companyId = null;
      this.getCompanies();
    },
    sameAddress() {
      this.copyAddress();
    }
  },
  methods: {
    async getOrganizations() {
      await _getOrganizations()
        .then((response) => {
          this.collections.organizations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    copyAddress() {
      if (!this.sameAddress) return;
      this.model.physicalAddress1 = this.model.postalAddress1;
      this.model.physicalAddress2 = this.model.postalAddress2;
      this.model.physicalCityId = this.model.postalCityId;
      this.model.physicalState = this.model.postalState;
      this.model.physicalCountry = this.model.postalCountry;
      this.model.physicalZipCode = this.model.postalZipCode;
      this.model.physicalZipCodeExt = this.model.postalZipCodeExt;
    },
    async getCompanies() {
      this.collections.companies = [];
      await _getCompanies(this.model.organizationId)
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      const payload = {
        ...this.model,
        locationConfigurationId: this.locationConfigurationId,
        rowId: this.rows.join(),
        serviceTypeId: this.services.join(),
      };
      await _copyOffice(payload).then(response => {
        this.ShowSuccessSaveToast();
        this.$router.push({name: 'OfficeEdit', params: {id: response.data}})
      });
    },
  },
};
</script>
