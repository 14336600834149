<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('geographicCoordinates')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <base-input
                      v-model="value.latitude"
                      :label="$t('latitude')"
                    />
                </div>
                <div class="col-md-12">
                  <base-input
                      v-model="value.longitude"
                      :label="$t('longitude')"
                    />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 mt-1">
                  <button class="btn btn-outline-info mt-4" @click="showCoordinatesModal = true">
                    {{ $t('getCoordinates') }}
                  </button>
                </div>
              </div>
            </div>

          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 10)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('save') }} & {{$t('finalize')}}
      </button>
    </div>
    <custom-modal
      v-model="showCoordinatesModal"
      :title="$t('getCoordinates')"
    >
    <Location v-if="showCoordinatesModal" :value="value" @close="showCoordinatesModal = false" />
    </custom-modal>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import Location from './Location.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import {
  getOfficeGeo as _getOfficeGeo,
  updateOfficeGeo as _updateOfficeGeo,
} from '@/services/LocationService';

export default {
  name: 'GeographicCoordinates',
  components: {
    SubHeader,
    BaseInput,
    CustomModal,
    Location
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showCoordinatesModal: false
    }
  },
   async mounted() {
    await _getOfficeGeo(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.latitude = data.latitude;
        this.value.longitude = data.longitude;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      await _updateOfficeGeo(this.value).then(() => {
        if (next) this.$router.push({ name: 'Offices' })
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
