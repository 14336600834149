<template>
  <div class="row">
    <div class="col-md-8">
      <div class="form-group">
        <label>{{ $t('findAddress') }}</label>
        <gmap-autocomplete
          :placeholder="$t('enterLocation')"
          class="form-control"
          @place_changed="setPlace"
        ></gmap-autocomplete>
      </div>
    </div>
    <div class="col-md-4 text-right pt-1">
      <button class="btn btn-outline-primary mt-4" @click="addMarker">
        {{ $t('selectAddress') }}
      </button>
    </div>
    <div class="col-md-12">
      <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 600px">
        <gmap-marker
          v-if="marker"
          :position="marker"
          ref="myMarker"
          :clickable="true"
          :draggable="true"
        ></gmap-marker>
      </gmap-map>
    </div>
    <div class="col-md-12 mt-3 text-right">
      <button class="btn btn-primary" @click="onSubmit">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Location',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      marker: {
        lat: 18.2526284,
        lng: -66.6051289,
      },
      currentPlace: null,
      zoom: 10,
      center: {
        lat: 18.2526284,
        lng: -66.6051289,
      },
    };
  },
  created() {
    if (this.value.latitude && this.value.longitude) {
      this.marker = { lat: +this.value.latitude, lng: +this.value.longitude };
      this.center = { ...this.marker };
      this.zoom = 17;
    }
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.marker = marker;
        this.center = marker;
        this.zoom = 17;
      } else this.zoom = 10
    },
    async onSubmit() {
      const position = (await this.$refs.myMarker.$markerPromise).position;
      this.value.latitude = (position.lat()).toString();
      this.value.longitude = (position.lng()).toString();
      this.$emit('close');
    }
  },
};
</script>
<style>
.pac-container {
  z-index: 9999999;
}
</style>
