<template>
  <div class="card pt-2 border-light search-result"
    :class="{
      grey: !value.enabled,
      lightgreen: value.enabled
    }">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6 col-md-6 col-lg-4">
          <p>
            <label>{{ $t('name') }} (ID): </label>
            <span>{{ value.locationName }} ({{ value.locationId }})</span>
          </p>
          <p>
            <label>{{ $t('organization') }} (ID): </label>
            <span>{{ value.organizationName }} ({{ value.organizationId }})</span>
          </p>
          <p>
            <label>{{ $t('company') }} (ID): </label>
            <span>{{ value.companyName }} ({{ value.companyId }})</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-6">
          <p>
            <label>{{ $t('configurationId') }}: </label>
            <span>{{ value.locationConfigurationId }}</span>
          </p>
          <p>
            <b-form-checkbox
              v-model="value.enabled"
              class="chk-available"
              :disabled="true"
              switch
            >
              {{ $t('available') }}
            </b-form-checkbox>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 pt-4">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'OfficeSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$router.push({name: 'OfficeEdit', params: {id: this.value.locationConfigurationId}}),
        },
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#24852E',
          action: () => this.$router.push({name: 'OfficeClone', params: {id: this.value.locationConfigurationId}}),
        },
        {
          label: this.$t(this.value.enabled ? 'deactivate' : 'activate'),
          iconClass: this.value.enabled ? 'far fa-user-unlock' : 'far fa-user-lock',
          iconColor: this.value.enabled ? '#FF0000' : '#FF8105',
          action: () => this.$emit('block')
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
        {
          label: this.$t('syncChatUsers'),
          iconClass: 'fab fa-rocketchat',
          iconColor: '#F5455C',
          action: () => this.$emit('on-sync-chat-users'),
        },
      ];
    }
  },
};
</script>

<style lang="scss" scoped>
.card.search-result {
  & p {
    margin-bottom: 0.2rem;
  }

  & p i {
    cursor: pointer;
    font-size: 18px;
  }

  & label {
    font-size: 14px;
    min-width: 100px;
    font-weight: bold;
  }

  & p i,
  & span {
    color: $color-primary;
    font-weight: bold;
    margin-left: 5px;
  }

  &.grey {
    border: 1px solid #d0cccc !important;
    background-color: #f8f8f8;
    ::v-deep .c-actionButton {
      border: 1px solid #d0cccc !important;
    }
  }

  &.lightgreen {
    border: 1px solid #a4afa5 !important;
    background-color: #f2fff2;
    ::v-deep .c-actionButton {
      border: 1px solid #a4afa5 !important;
    }
  }
}
</style>
