<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('extraField1100')" />
              </div>
            </div>
            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField1Enabled" switch>
                    {{ $t('activateSpecialField') }} 1
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField1Name"
                    :label="`${$t('specialFieldName')} 1`"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider
                    :rules="`allowed_characters:${maskRules}`"
                    v-slot="{ errors }"
                  >
                    <base-input
                      v-model="value.specialField1Mask"
                      :placeholder="`${$t('placeholderMask')} 1`"
                      :error="errors[0]"
                      :error-msg="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField1Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField1ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField1ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField1ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField1ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField2Enabled" switch>
                    {{ $t('activateSpecialField') }} 2
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField2Name"
                    :label="`${$t('specialFieldName')} 2`"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider
                    :rules="`allowed_characters:${maskRules}`"
                    v-slot="{ errors }"
                  >
                    <base-input
                      v-model="value.specialField2Mask"
                      :placeholder="`${$t('placeholderMask')} 2`"
                      :error="errors[0]"
                      :error-msg="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField2Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField2ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField2ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField2ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField2ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField3Enabled" switch>
                    {{ $t('activateSpecialField') }} 3
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField3Name"
                    :label="`${$t('specialFieldName')} 3`"
                  />
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField3Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField3ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField3ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField3ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField3ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <button
                    class="btn btn-outline-info"
                    @click="showSpecialField3Modal = true"
                  >
                    <i class="fas fa-plus fa-lg"></i> {{ $t('values') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField4Enabled" switch>
                    {{ $t('activateSpecialField') }} 4
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField4Name"
                    :label="`${$t('specialFieldName')} 4`"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider
                    :rules="`allowed_characters:${maskRules}`"
                    v-slot="{ errors }"
                  >
                    <base-input
                      v-model="value.specialField4Mask"
                      :placeholder="`${$t('placeholderMask')} 4`"
                      :error="errors[0]"
                      :error-msg="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField4Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField4ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField4ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField4ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField4ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField5Enabled" switch>
                    {{ $t('activateSpecialField') }} 5
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField5Name"
                    :label="`${$t('specialFieldName')} 5`"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider
                    :rules="`allowed_characters:${maskRules}`"
                    v-slot="{ errors }"
                  >
                    <base-input
                      v-model="value.specialField5Mask"
                      :placeholder="`${$t('placeholderMask')} 5`"
                      :error="errors[0]"
                      :error-msg="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField5Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField5ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField5ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField5ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField5ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField6Enabled" switch>
                    {{ $t('activateSpecialField') }} 6
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField6Name"
                    :label="`${$t('specialFieldName')} 6`"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider
                    :rules="`allowed_characters:${maskRules}`"
                    v-slot="{ errors }"
                  >
                    <base-input
                      v-model="value.specialField6Mask"
                      :placeholder="`${$t('placeholderMask')} 6`"
                      :error="errors[0]"
                      :error-msg="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField6Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField6ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField6ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField6ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField6ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField7Enabled" switch>
                    {{ $t('activateSpecialField') }} 7
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="value.specialField7Name"
                    :label="`${$t('specialFieldName')} 7`"
                  />
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.specialField7Required" switch>
                    {{ $t('required') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField7ShowInHandler"
                    switch
                  >
                    {{ $t('showInTurnHandler') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField7ShowInAptsMobile"
                    switch
                  >
                    {{ $t('showMobileAppointments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField7ShowInAptsWeb"
                    switch
                  >
                    {{ $t('showWebQuotes') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.specialField7ShowInTurnosMobile"
                    switch
                  >
                    {{ $t('showMobileTurns') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 8)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
    <custom-modal
      v-model="showSpecialField3Modal"
      :title="$t('miscellaneousFieldValues')"
      size="lg"
    >
      <SpecialField3Values
        :location-configuration-id="value.locationConfigurationId"
        v-if="showSpecialField3Modal"
        @close="showSpecialField3Modal = false"
      />
    </custom-modal>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import SpecialField3Values from './SpecialField3Values.vue';
import {
  getOfficeExtrafields as _getOfficeExtrafields,
  updateOfficeExtrafields as _updateOfficeExtrafields,
} from '@/services/LocationService';
import { allowedMaskCharacters } from '@/config/validation-config';

export default {
  name: 'SpecialField',
  components: {
    SubHeader,
    BaseInput,
    CustomModal,
    SpecialField3Values,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showSpecialField3Modal: false,
    };
  },
  computed: {
    maskRules() {
      return `max:50|allowed_characters:${allowedMaskCharacters}`;
    },
  },
  async mounted() {
    await _getOfficeExtrafields(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.specialField1Enabled = data.specialField1Enabled;
        this.value.specialField1Name = data.specialField1Name;
        this.value.specialField1Required = data.specialField1Required;
        this.value.specialField1ShowInHandler = data.specialField1ShowInHandler;
        this.value.specialField1ShowInAptsMobile =
          data.specialField1ShowInAptsMobile;
        this.value.specialField1ShowInAptsWeb = data.specialField1ShowInAptsWeb;
        this.value.specialField1ShowInTurnosMobile =
          data.specialField1ShowInTurnosMobile;
        this.value.specialField1Mask = data.specialField1Mask;

        this.value.specialField2Enabled = data.specialField2Enabled;
        this.value.specialField2Name = data.specialField2Name;
        this.value.specialField2Required = data.specialField2Required;
        this.value.specialField2ShowInHandler = data.specialField2ShowInHandler;
        this.value.specialField2ShowInAptsMobile =
          data.specialField2ShowInAptsMobile;
        this.value.specialField2ShowInAptsWeb = data.specialField2ShowInAptsWeb;
        this.value.specialField2ShowInTurnosMobile =
          data.specialField2ShowInTurnosMobile;
        this.value.specialField2Mask = data.specialField2Mask;

        this.value.specialField3Enabled = data.specialField3Enabled;
        this.value.specialField3Name = data.specialField3Name;
        this.value.specialField3Required = data.specialField3Required;
        this.value.specialField3ShowInHandler = data.specialField3ShowInHandler;
        this.value.specialField3ShowInAptsMobile =
          data.specialField3ShowInAptsMobile;
        this.value.specialField3ShowInAptsWeb = data.specialField3ShowInAptsWeb;
        this.value.specialField3ShowInTurnosMobile =
          data.specialField3ShowInTurnosMobile;

        this.value.specialField4Enabled = data.specialField4Enabled;
        this.value.specialField4Name = data.specialField4Name;
        this.value.specialField4Required = data.specialField4Required;
        this.value.specialField4ShowInHandler = data.specialField4ShowInHandler;
        this.value.specialField4ShowInAptsMobile =
          data.specialField4ShowInAptsMobile;
        this.value.specialField4ShowInAptsWeb = data.specialField4ShowInAptsWeb;
        this.value.specialField4ShowInTurnosMobile =
          data.specialField4ShowInTurnosMobile;
        this.value.specialField4Mask = data.specialField4Mask;

        this.value.specialField5Enabled = data.specialField5Enabled;
        this.value.specialField5Name = data.specialField5Name;
        this.value.specialField5Required = data.specialField5Required;
        this.value.specialField5ShowInHandler = data.specialField5ShowInHandler;
        this.value.specialField5ShowInAptsMobile =
          data.specialField5ShowInAptsMobile;
        this.value.specialField5ShowInAptsWeb = data.specialField5ShowInAptsWeb;
        this.value.specialField5ShowInTurnosMobile =
          data.specialField5ShowInTurnosMobile;
        this.value.specialField5Mask = data.specialField5Mask;

        this.value.specialField6Enabled = data.specialField6Enabled;
        this.value.specialField6Name = data.specialField6Name;
        this.value.specialField6Required = data.specialField6Required;
        this.value.specialField6ShowInHandler = data.specialField6ShowInHandler;
        this.value.specialField6ShowInAptsMobile =
          data.specialField6ShowInAptsMobile;
        this.value.specialField6ShowInAptsWeb = data.specialField6ShowInAptsWeb;
        this.value.specialField6ShowInTurnosMobile =
          data.specialField6ShowInTurnosMobile;
        this.value.specialField6Mask = data.specialField6Mask;

        this.value.specialField7Enabled = data.specialField7Enabled;
        this.value.specialField7Name = data.specialField7Name;
        this.value.specialField7Required = data.specialField7Required;
        this.value.specialField7ShowInHandler = data.specialField7ShowInHandler;
        this.value.specialField7ShowInAptsMobile =
          data.specialField7ShowInAptsMobile;
        this.value.specialField7ShowInAptsWeb = data.specialField7ShowInAptsWeb;
        this.value.specialField7ShowInTurnosMobile =
          data.specialField7ShowInTurnosMobile;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          await _updateOfficeExtrafields(this.value).then(() => {
            if (next) this.$emit('continue', 10);
            this.ShowSuccessSaveToast();
          });
        }
      });
    },
  },
};
</script>
