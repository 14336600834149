<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('handlerParameters')" />
              </div>

            </div>
            <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <filter-numeric
                      v-model="value.noShowDisplayTimeInSeconds"
                      :title="$t('minimumTimeToWaitMarkNoShow')"
                      :min="0"
                    />
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.hasAutomaticPull" switch>
                      {{ $t('callNextAutomatically') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox
                      v-model="value.isAutomaticEquitative"
                      switch
                    >
                      {{ $t('systemAllocatesNextEqually') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.canReasignQueue" switch>
                      {{ $t('reassingToRow') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.canEditClientInfo" switch>
                      {{ $t('editCustomerInformationBeingServed') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.hasReadonlyService" switch>
                      {{ $t('readOnlyService') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                 <div class="row">
                  <div class="col-md-12">
                    <filter-numeric
                      v-model="value.serviceEndTime"
                      :title="$t('endOfServiceTime')"
                      :min="0"
                    />
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.hasAutomaticPush" switch>
                      {{ $t('systemAssignsNextAutomatically') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox
                      v-model="value.isBreakModuleEnabled"
                      switch
                    >
                      {{ $t('recessModule') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.hasIndividualServiceCounter" switch>
                      {{ $t('individualServiceCounter') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.canCallIndividually" switch>
                      {{ $t('singleCall') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="value.canRecoordinateAppointments" switch>
                      {{ $t('recoordinateAppointment') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 1)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import {
  getOfficeTurnhandler as _getOfficeTurnhandler,
  updateOfficeTurnhandler as _updateOfficeTurnhandler,
} from '@/services/LocationService';

export default {
  name: 'manangerParameters',
  components: {
    SubHeader
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await _getOfficeTurnhandler(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.noShowDisplayTimeInSeconds = data.noShowDisplayTimeInSeconds;
        this.value.hasAutomaticPull = data.hasAutomaticPull;
        this.value.isAutomaticEquitative = data.isAutomaticEquitative;
        this.value.canReasignQueue = data.canReasignQueue;
        this.value.canEditClientInfo = data.canEditClientInfo;
        this.value.hasReadonlyService = data.hasReadonlyService;

        this.value.serviceEndTime = data.serviceEndTime;
        this.value.hasAutomaticPush = data.hasAutomaticPush;
        this.value.isBreakModuleEnabled = data.isBreakModuleEnabled;
        this.value.hasIndividualServiceCounter = data.hasIndividualServiceCounter;
        this.value.canCallIndividually = data.canCallIndividually;
        this.value.canRecoordinateAppointments = data.canRecoordinateAppointments;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      await _updateOfficeTurnhandler(this.value).then(() => {
        if (next) this.$emit('continue', 3);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
