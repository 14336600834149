<template>
  <div class="row" style="min-height: 300px">
    <div class="col-md-12">
      <b-table
        sticky-header
        :fields="fields"
        :items="notifications"
        striped
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(subject)="row">
          <div class="cursor-pointer" @click="row.toggleDetails">
            {{ row.item.subject }}
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="row">
              <div class="col-md-12">
                {{ row.item.text }}
              </div>
            </div>
          </b-card>
        </template>
      </b-table>
      <b-pagination
        v-if="rows"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { getOfficeNotifications as _getOfficeNotifications } from '@/services/LocationService';

export default {
  name: 'Notifications',
  props: {
    locationConfigurationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id', stickyColumn: true, isRowHeader: true },
        { key: 'name', label: 'Nombre', stickyColumn: true, isRowHeader: true },
        {
          key: 'subject',
          label: 'Titulo',
          stickyColumn: true,
          isRowHeader: true,
        },
      ],
      notifications: [],
      perPage: 5,
      currentPage: 1,
    };
  },
  async mounted() {
    await _getOfficeNotifications(this.locationConfigurationId).then(
      (response) => {
        this.notifications = response.data;
      },
    );
  },
  computed: {
    rows() {
      return this.notifications.length;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep thead {
  tr {
    th {
      background-color: #ff8105 !important;
      color: white !important;
    }
  }
}
</style>
