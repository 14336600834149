<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('surveyModule')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.enabledSurvey" switch>
                    {{ $t('activateSurvery') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.companyId"
                      :clearable="true"
                      fieldtext="text"
                      fieldvalue="value"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('company')"
                      :placeholder="$t('select')"
                      :options="collections.companies"
                      @change="onCompanyChanged($event)"
                      :disabled="!!value.companyId"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.locationConfigurationId"
                      :clearable="true"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :placeholder="$t('select')"
                      :label="$t('activeLocation')"
                      :options="collections.locations"
                      @change="onLocationChanged($event)"
                      :disabled="!!value.locationConfigurationId"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.remoteSurveyId"
                      :clearable="true"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('surveryId')"
                      :options="collections.surverys"
                      :value="value.remoteSurveyId"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="value.remoteLocationId"
                      :clearable="true"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('officeIdInSurverys')"
                      :options="collections.surverysLoc"
                      :value="value.remoteLocationId"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 4)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { getSurveyByLocation as _getSurveyByLocation } from '@/services/SurveyService';
import { getSurveyLocationsList as _getSurveyLocationsList } from '@/services/LocationService';
import {
  getOfficeSurvey as _getOfficeSurvey,
  updateOfficeSurvey as _updateOfficeSurvey,
} from '@/services/LocationService';

export default {
  name: 'surveyModule',
  components: { SubHeader },
  created() {},
  data() {
    return {
      collections: {
        companies: [],
        locations: [],
        surverys: [],
        surverysLoc: [],
      }
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await _getOfficeSurvey(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.enabledSurvey = data.enabled;
        this.value.remoteSurveyId = data.remoteSurveyId;
        this.value.remoteLocationId = data.remoteLocationId;
      },
    );
    await this.getCompanies();
  },

  methods: {
    async onSubmit(next = false) {
      await _updateOfficeSurvey({
        ...this.value,
        enabled: this.value.enabledSurvey,
      }).then(() => {
        if (next) this.$emit('continue', 6);
        this.ShowSuccessSaveToast();
      });
    },
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
          if (this.value.companyId) this.getLocationList(this.value.companyId);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocationList(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.collections.locations = response.data || [];
          if (this.value.locationConfigurationId)
            this.getSurveyByLocation(this.value.locationConfigurationId);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSurveyByLocation(locationConfigurationId) {
      await _getSurveyByLocation(locationConfigurationId)
        .then((response) => {
          this.collections.surverys = response.data || [];
          if (locationConfigurationId)
            this.getSurveyLocationsList(locationConfigurationId);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSurveyLocationsList(locationConfigurationId) {
      await _getSurveyLocationsList(locationConfigurationId)
        .then((response) => {
          this.collections.surverysLoc = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.collections.locations = [];
      if (value) {
        this.getLocationList([value]);

      }
    },
    onLocationChanged(value) {
      this.collections.surverys = [];
      this.collections.surverysLoc = [];
      if (value) {
        this.getSurveyByLocation([value]);
        this.getSurveyLocationsList([value]);
      }
    },
  },
};
</script>
