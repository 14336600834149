<template>
  <div class="row">
    <div class="col-md-12">
      <b-form-group>
        <b-form-radio v-model="specific" :value="false">
          {{ $t('selectThePrinterMarkedByDefault') }}
        </b-form-radio>
        <b-form-radio v-model="specific" :value="true">
          {{ $t('selectTheSpecificPrinter') }}
        </b-form-radio>
      </b-form-group>
    </div>
    <div v-if="specific" class="col-md-12 mt-2">
      <div class="row">
        <div class="col-md-12">
          <base-input v-model="printerName" :label="$t('selectedPrinter')" />
        </div>
      </div>
    </div>
    <div class="col-md-12 text-right mt-3">
      <base-filled-button
        class="float-right"
        :on-click="onClose"
        :text="$t('close')"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
export default {
  name: 'PrinterForm',
  components: {
    BaseInput
  },
  props: {
    isSpecific: {
      type: Boolean,
      default: () => false
    },
    printerName: {
      type: String,
      default: () => null
    },
  },
  data() {
    return {
      specific: this.isSpecific,
    };
  },
  methods: {
    onClose() {
      this.$emit('on-close', {
        isSpecific: this.specific,
        printerName: this.specific ? this.printerName : null
      });
    }
  }
};
</script>
