<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('printerParameters')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.isPrinterEnabled" switch>
                    {{ $t('enablePrinter') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <button class="btn btn-outline-info" @click="showPrinterModal = true">
                    {{ $t('configurePrinter') }}
                  </button>
                </div>
                <div class="col-md-12 mt-2">
                  <filter-numeric
                    v-model="value.copiesToPrint"
                    :title="$t('waitingTimePerShiftInMinuts')"
                    :min="0"
                  />
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 2)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
    <custom-modal
      v-model="showPrinterModal"
      :title="$t('printerSetup')"
      size="md"
    >
      <PrinterForm
        v-if="showPrinterModal"
        :is-specific="value.isSpecific"
        :printer-name="value.printerName"
        @on-close="onFormClose($event)"
      />
    </custom-modal>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import PrinterForm from './PrinterForm.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import {
  getOfficePrinter as _getOfficePrinter,
  updateOfficePrinter as _updateOfficePrinter,
} from '@/services/LocationService';

export default {
  name: 'printerParameters',
  components: {
    SubHeader,
    PrinterForm,
    CustomModal
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPrinterModal: false
    }
  },
  async mounted() {
    await _getOfficePrinter(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.isPrinterEnabled = data.isPrinterEnabled;
        this.value.copiesToPrint = data.copiesToPrint;
        this.value.isSpecific = data.isSpecific;
        this.value.printerName = data.printerName;
      },
    );
  },
  methods: {
    onFormClose(value) {
      this.value.isSpecific = value.isSpecific;
      this.value.printerName = value.printerName;
      this.showPrinterModal = false;
    },
    async onSubmit(next = false) {
      await _updateOfficePrinter(this.value).then(() => {
        if (next) this.$emit('continue', 4);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
