<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('alerts')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 text-right">
                  <button
                    class="btn btn-outline-info"
                    @click="showAlertModal = true"
                  >
                    <i class="far fa-plus" /> {{ $t('add') }}
                  </button>
                </div>
                <div class="col-md-12 mt-2">
                  <ul class="list-group">
                    <li
                      v-for="(item, i) in configurations"
                      class="list-group-item"
                      :key="i"
                    >
                      {{ item.name }}
                      <button
                        class="btn btn-danger float-right"
                        @click="onDelete(item.id)"
                      >
                        <i class="far fa-trash" />
                      </button>
                    </li>
                    <li v-if="!configurations.length" class="list-group-item">
                      <CustomNotFound :text="$t('noResultsFound')" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 7)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-primary ml-2" @click="$emit('continue', 9)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('continue') }}
      </button>
    </div>
    <custom-modal
      v-model="showAlertModal"
      :title="$t('localAlertsConfiguration')"
      size="lg"
    >
      <AlertForm
        v-if="showAlertModal"
        :location-configuration-id="value.locationConfigurationId"
        :alerts="alerts"
        :roles="roles"
        @close="loadConfigurations()"
      />
    </custom-modal>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import AlertForm from './AlertForm.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import {
  getOfficeAlerts as _getOfficeAlerts,
  getOfficeAlertConfigurations as _getOfficeAlertConfigurations,
  deleteOfficeAlertConfiguration as _deleteOfficeAlertConfiguration,
} from '@/services/LocationService';
import { getAlertRoles as _getAlertRoles } from '@/services/RoleService';

export default {
  name: 'Alerts',
  components: {
    SubHeader,
    CustomNotFound,
    AlertForm,
    CustomModal,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      configurations: [],
      roles: [],
      alerts: [],
      showAlertModal: false,
    };
  },
  async mounted() {
    await _getAlertRoles().then((response) => {
      this.roles = response.data;
    });
    await _getOfficeAlerts().then((response) => {
      this.alerts = response.data;
    });
    await this.loadConfigurations();
  },
  methods: {
    async loadConfigurations() {
      this.showAlertModal = false;
      await _getOfficeAlertConfigurations(
        this.value.locationConfigurationId,
      ).then((response) => {
        this.configurations = response.data;
      });
    },
    async onDelete(id) {
      await _deleteOfficeAlertConfiguration(id).then(() => this.loadConfigurations());
    },
  },
};
</script>
