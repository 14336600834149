<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('officeParameter')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <filter-numeric
                    v-model="value.turnWaitingTime"
                    :min="0"
                    :title="$t('waitingTimePerShiftInMinuts')"
                  />
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.hasAutomaticService" switch>
                    {{ $t('automaticRow') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.hasMobileAccess" switch>
                    {{ $t('mobileAccess') }}
                  </b-form-checkbox>
                </div>

                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canCreateAccount" switch>
                    {{ $t('createPublicAccount') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canAutoClearList" switch>
                    {{ $t('autoCleanListing') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.isClientPhoneRequired" switch>
                    {{ $t('requireTelephone') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canActivatePWA" switch>
                    {{ $t('activatePWA') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canAskForStation" switch>
                    {{ $t('askForStation') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canActivateOffice" switch>
                    {{ $t('activeDeactiveOffice') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.transTracking" switch>
                    {{ $t('transTracking') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.nextDayAttentionNotification"
                    switch
                  >
                    {{ $t('nextDayAttentionNotification') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.priorityCallingTurnByQueue"
                    switch
                  >
                    {{ $t('priorityWhenCallingTurnByRow') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.priorityCallingTurnByService"
                    switch
                  >
                    {{ $t('priorityWhenCallingTurnByService') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.preferentialTurn" switch>
                    {{ $t('preferentialTurn') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.mobileCanFollowTurn" switch>
                    {{ $t('youCanFollowTurnMobile') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.notPresentNotification"
                    switch
                  >
                    {{ $t('notPresentNotification') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.hideBlockedAttenders" switch>
                    {{ $t('hideBlockedUsersInVisitTo') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.canUploadDocuments" switch>
                    {{ $t('canUploadDocuments') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.enableVisitMode" switch>
                    {{ $t('enableVisitMode') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox :disabled="!haveCommunicationModule" v-model="value.enableChat" switch>
                    {{ $t('activateChatUse') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.enableTurnManualCompletion"
                    switch
                    :disabled="!value.enableVisitMode"
                  >
                    {{ $t('enableTurnManualCompletion') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox
                    v-model="value.enableLanguageBehavior"
                    switch
                    :disabled="!collections.languages.length"
                  >
                    {{ $t('enableLanguageBehavior') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.enableEntrance" switch>
                    {{ $t('activateEntrance') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="value.scannerActive" switch>
                    {{ $t('activateScanner') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <filter-numeric
                    v-model="value.alertWithTurnCount"
                    :min="0"
                    :title="$t('alertWithXNumberInAdvance')"
                  />
                </div>
                <div class="col-md-12">
                  <filter-select
                    v-model="value.automaticQueueType"
                    :clearable="false"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('automaticRowType')"
                    :options="collections.automaticQueueTypes"
                  />
                </div>
                <div class="col-md-12">
                  <filter-numeric
                    v-model="value.recordsPerPage"
                    :min="0"
                    :title="$t('recordsPerPage')"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-group :label="`${$t('officeOpeningHours')}:`">
                      <base-datetime-picker
                        class="theme-orange"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        icon-class="clock"
                        is-readonly="true"
                        type="time"
                        :value="value.openTime"
                        @change="value.openTime = $event"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-group :label="`${$t('officeClosingHours')}:`">
                      <base-datetime-picker
                        class="theme-orange"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        icon-class="clock"
                        is-readonly="true"
                        type="time"
                        :value="value.closeTime"
                        @change="value.closeTime = $event"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.autoCloseUserAccount" switch>
                    {{ $t('autoCloseUserAccounts') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg-12">
                  <base-datetime-picker
                    class="theme-orange"
                    icon-class="clock"
                    is-readonly="true"
                    :title="$t('hourFromMinimumCapacity')"
                    type="time"
                    :value="value.hourFromMinimumCapacity"
                    @change="value.hourFromMinimumCapacity = $event"
                  />
                </div>
                <div class="col-lg-12">
                  <base-datetime-picker
                    class="theme-orange"
                    icon-class="clock"
                    is-readonly="true"
                    :title="$t('hourToMinimumCapacity')"
                    type="time"
                    :value="value.hourToMinimumCapacity"
                    @change="value.hourToMinimumCapacity = $event"
                  />
                </div>
                <div class="col-lg-12 mt-1">
                  <filter-numeric
                    v-model="value.increasedWaitingTime"
                    :max="100"
                    :min="1"
                    title="increasedWaitingTime"
                  />
                </div>
                <div class="col-12">
                  <filter-select
                    v-model="value.preferredLanguageId"
                    clearable
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('language')"
                    :options="collections.languages"
                  />
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 0)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import {
  getOfficeParameters as _getOfficeParameters,
  updateOfficeParameters as _updateOfficeParameters,
  haveCommunicationModuleEnabled as _haveCommunicationModuleEnabled
} from '@/services/LocationService';
import { getLanguages as _getLanguages } from '@/services/CompanyService';
import moment from 'moment';

export default {
  name: 'OfficeParameter',
  components: {
    SubHeader,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collections: {
        languages: [],
        automaticQueueTypes: [
          { value: 1, text: this.$t('sequential') },
          { value: 2, text: this.$t('alternated') },
        ],
      },
      haveCommunicationModule: false
    };
  },
  watch: {
    'value.enableVisitMode'(value) {
      if (!value) {
        this.value.enableTurnManualCompletion = value;
      }
    },
  },
  async mounted() {
    await _getLanguages(this.value.companyId).then(({ data }) => {
      this.collections.languages = data;
    });
    await _haveCommunicationModuleEnabled(this.value.locationConfigurationId).then(({ data }) => {
      this.haveCommunicationModule = data;
    });
    await _getOfficeParameters(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.turnWaitingTime = data.turnWaitingTime;
        this.value.hasAutomaticService = data.hasAutomaticService;
        this.value.hasMobileAccess = data.hasMobileAccess;
        this.value.isClientPhoneRequired = data.isClientPhoneRequired;
        this.value.canCreateAccount = data.canCreateAccount;
        this.value.canAutoClearList = data.canAutoClearList;
        this.value.canActivatePWA = data.canActivatePWA;
        this.value.canAskForStation = data.canAskForStation;
        this.value.canActivateOffice = data.canActivateOffice;
        this.value.alertWithTurnCount = data.alertWithTurnCount;
        this.value.automaticQueueType = data.automaticQueueType;
        this.value.recordsPerPage = data.recordsPerPage;
        this.value.transTracking = data.transTracking;
        this.value.nextDayAttentionNotification =
          data.nextDayAttentionNotification;
        this.value.priorityCallingTurnByRow = data.priorityCallingTurnByRow;
        this.value.priorityCallingTurnByService =
          data.priorityCallingTurnByService;
        this.value.priorityCallingTurnByQueue = data.priorityCallingTurnByQueue;
        this.value.preferentialTurn = data.preferentialTurn;
        this.value.mobileCanFollowTurn = data.mobileCanFollowTurn;
        this.value.notPresentNotification = data.notPresentNotification;
        this.value.hideBlockedAttenders = data.hideBlockedAttenders;
        this.value.canUploadDocuments = data.canUploadDocuments;
        this.value.enableLanguageBehavior = data.enableLanguageBehavior;
        this.value.increasedWaitingTime = data.increasedWaitingTime;
        this.value.enableVisitMode = data.enableVisitMode;
        this.value.enableChat = data.enableChat;
        this.value.enableEntrance = data.enableEntrance;
        this.value.preferredLanguageId = data.preferredLanguageId;
        this.value.enableTurnManualCompletion = data.enableTurnManualCompletion;
        this.value.scannerActive = data.scannerActive;

        if (data.openTime) {
          const openTime = moment(data.openTime, 'hh:mm:ss');
          this.value.openTime = moment()
            .set({
              hours: openTime.hours(),
              minutes: openTime.minutes(),
              second: 0,
              millisecond: 0,
            })
            .format();
        } else this.value.openTime = null;

        if (data.closeTime) {
          const closeTime = moment(data.closeTime, 'hh:mm:ss');
          this.value.closeTime = moment()
            .set({
              hours: closeTime.hours(),
              minutes: closeTime.minutes(),
              second: 0,
              millisecond: 0,
            })
            .format();
        } else this.value.closeTime = null;

        if (data.hourFromMinimumCapacity) {
          const hourFromMinimumCapacity = moment(
            data.hourFromMinimumCapacity,
            'hh:mm:ss',
          );
          this.value.hourFromMinimumCapacity = moment()
            .set({
              hours: hourFromMinimumCapacity.hours(),
              minutes: hourFromMinimumCapacity.minutes(),
              second: 0,
              millisecond: 0,
            })
            .format();
        } else this.value.hourFromMinimumCapacity = null;

        if (data.hourToMinimumCapacity) {
          const hourToMinimumCapacity = moment(
            data.hourToMinimumCapacity,
            'hh:mm:ss',
          );
          this.value.hourToMinimumCapacity = moment()
            .set({
              hours: hourToMinimumCapacity.hours(),
              minutes: hourToMinimumCapacity.minutes(),
              second: 0,
              millisecond: 0,
            })
            .format();
        } else this.value.hourToMinimumCapacity = null;

        this.value.autoCloseUserAccount = data.autoCloseUserAccount;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;
      await _updateOfficeParameters({
        ...this.value,
        openTime: this.value.openTime
          ? moment(this.value.openTime).set({ seconds: 0 }).format('LTS')
          : null,
        closeTime: this.value.closeTime
          ? moment(this.value.closeTime).set({ seconds: 0 }).format('LTS')
          : null,
        hourFromMinimumCapacity: this.value.hourFromMinimumCapacity
          ? moment(this.value.hourFromMinimumCapacity)
              .set({ seconds: 0 })
              .format('LTS')
          : null,
        hourToMinimumCapacity: this.value.hourToMinimumCapacity
          ? moment(this.value.hourToMinimumCapacity)
              .set({ seconds: 0 })
              .format('LTS')
          : null,
      }).then(() => {
        if (next) this.$emit('continue', 2);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
