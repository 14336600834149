<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('integrations')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.enableColecturiaDigital" switch>
                    {{ $t('activateDigitalCollection') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.askIdWhenCancelingStamp" switch>
                    {{ $t('askForIdWhenCancelingStamp') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="value.enableSingleSignOn" switch>
                    {{ $t('activateSingleSignOn') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 6)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import {
  getOfficeIntegration as _getOfficeIntegration,
  updateOfficeIntegration as _updateOfficeIntegration,
} from '@/services/LocationService';

export default {
  name: 'integrations',
  components: {
    SubHeader,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
   async mounted() {
    await _getOfficeIntegration(this.value.locationConfigurationId).then(
      ({ data }) => {
        this.value.enableColecturiaDigital = data.enableColecturiaDigital;
        this.value.enableSingleSignOn = data.enableSingleSignOn;
        this.value.enableMSTeams = data.enableMSTeams;
        this.value.askIdWhenCancelingStamp = data.askIdWhenCancelingStamp;
      },
    );
  },
  methods: {
    async onSubmit(next = false) {
      await _updateOfficeIntegration(this.value).then(() => {
        if (next) this.$emit('continue', 8);
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
