<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('address1200')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="text-center" style="color: #ff8105">
                    {{ $t('addressPostal') }}
                  </h3>
                </div>

                <div class="col-md-12 mt-4">
                  <validation-provider v-slot="{ errors }" rules="max:200">
                    <base-input
                      v-model="value.postalAddress1"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('urbCondResAptdo')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:200">
                    <base-input
                      v-model="value.postalAddress2"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('numberStreetApt')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <filter-select
                    v-model="value.postalCityId"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('city')"
                    :options="collections.municipalities"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:2">
                    <base-input
                      v-model="value.postalState"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('state')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:50">
                    <base-input
                      v-model="value.postalCountry"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('country')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:10">
                    <base-input
                      v-model="value.postalZipCode"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('postalCode')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:4">
                    <base-input
                      v-model="value.postalZipCodeExt"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('postalCodeExt')"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="text-center" style="color: #ff8105">
                    {{ $t('physicalAddress') }}
                  </h3>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="sameAddress"
                    switch
                  >
                    {{ $t('physicalAddressEqualToPostal') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:200">
                    <base-input
                      v-model="value.physicalAddress1"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('urbCondResAptdo')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:200">
                    <base-input
                      v-model="value.physicalAddress2"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('numberStreetApt')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <filter-select
                    v-model="value.physicalCityId"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('city')"
                    :options="collections.municipalities"
                    :disabled="sameAddress"
                  />
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:2">
                    <base-input
                      v-model="value.physicalState"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('state')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:50">
                    <base-input
                      v-model="value.physicalCountry"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('country')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:10">
                    <base-input
                      v-model="value.physicalZipCode"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('postalCode')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>

                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:4">
                    <base-input
                      v-model="value.physicalZipCodeExt"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('postalCodeExt')"
                      :disabled="sameAddress"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Offices' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-secondary float-left ml-2"
        @click="$emit('continue', 9)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>

      <button class="btn btn-outline-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('saveContinue') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import { getMunicipalities as _getMunicipalities } from '@/services/MunicipalityService';
import {
  getOfficeAddress as _getOfficeAddress,
  updateOfficeAdress as _updateOfficeAdress,
} from '@/services/LocationService';

export default {
  name: 'Address',
  components: {
    SubHeader,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collections: {
        municipalities: [],
      },
      sameAddress: false
    };
  },
  async mounted() {
    await _getMunicipalities({ stateCode: 'PR' })
      .then((response) => {
        this.collections.municipalities = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    this.loadAddress();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.copyAddress();
      }
    },
    sameAddress() {
      this.copyAddress();
    }
  },
  computed: {
    isSameAddress() {
      return (
        (this.value.postalAddress1 ||
          this.value.postalAddress2 ||
          this.value.postalCityId ||
          this.value.postalState ||
          this.value.postalCountry ||
          this.value.postalZipCode ||
          this.value.postalZipCodeExt) &&
        this.value.physicalAddress1 == this.value.postalAddress1 &&
        this.value.physicalAddress2 == this.value.postalAddress2 &&
        this.value.physicalCityId == this.value.postalCityId &&
        this.value.physicalState == this.value.postalState &&
        this.value.physicalCountry == this.value.postalCountry &&
        this.value.physicalZipCode == this.value.postalZipCode &&
        this.value.physicalZipCodeExt == this.value.postalZipCodeExt
      );
    },
  },
  methods: {
    async loadAddress() {
      await _getOfficeAddress(this.value.locationConfigurationId).then(
        ({ data }) => {
          this.value.physicalAddressId = data.physicalAddressId;
          this.value.physicalAddress1 = data.physicalAddress1;
          this.value.physicalAddress2 = data.physicalAddress2;
          this.value.physicalCityId = data.physicalCityId;
          this.value.physicalState = data.physicalState;
          this.value.physicalCountry = data.physicalCountry;
          this.value.physicalZipCode = data.physicalZipCode;
          this.value.physicalZipCodeExt = data.physicalZipCodeExt;

          this.value.postalAddressId = data.postalAddressId;
          this.value.postalAddress1 = data.postalAddress1;
          this.value.postalAddress2 = data.postalAddress2;
          this.value.postalCityId = data.postalCityId;
          this.value.postalState = data.postalState;
          this.value.postalCountry = data.postalCountry;
          this.value.postalZipCode = data.postalZipCode;
          this.value.postalZipCodeExt = data.postalZipCodeExt;

          this.sameAddress = this.isSameAddress;
          this.$emit('disabled-geographic', !this.value.physicalAddressId);
        },
      );
    },
    copyAddress() {
      if (!this.sameAddress) return;
      this.value.physicalAddress1 = this.value.postalAddress1;
      this.value.physicalAddress2 = this.value.postalAddress2;
      this.value.physicalCityId = this.value.postalCityId;
      this.value.physicalState = this.value.postalState;
      this.value.physicalCountry = this.value.postalCountry;
      this.value.physicalZipCode = this.value.postalZipCode;
      this.value.physicalZipCodeExt = this.value.postalZipCodeExt;
    },
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;
      await _updateOfficeAdress(this.value).then(() => {
        this.$emit('disabled-geographic', false);
        if (next) this.$emit('continue', 11);
        else this.loadAddress();
        this.ShowSuccessSaveToast();
      });
    },
  },
};
</script>
